import React, { useState } from "react";

import { useMeta, useNotification } from "@/hooks";
import { Tabs, Portlet, Icon, Spinner } from "@/components";
import { Button } from "reactstrap";
import _ from "lodash";
import {
    QUERY_GET_CUSTOMER,
    QUERY_GET_IDP_ACCOUNT,
    MUTATION_UPDATE_IDP_ACCOUNT,
} from "../../apollo/module-operations/customer";
import {
    MUTATION_UPDATE_ACCOUNT,
    MUTATION_FORGOT_USER_PASSWORD,
    MUTATION_UPDATE_ACCOUNT_BERBIX_VERIFICATION_STATUS,
} from "../../apollo/module-operations/account";

import { Content } from "@/layout";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import GeneralPane from "./tabs_panels/GeneralPane";
import VerificationPane from "./tabs_panels/VerificationPane";
import { useForm, useValidator } from "@/hooks";
import { useMutation } from "@apollo/react-hooks";
import ActivityLogs from "./tabs_panels/ActivityLogs";
import Notes from "./tabs_panels/Notes";

const CustomerForm = () => {
    const [loading, setLoading] = useState(true);
    const [statusBerbix, setStatusBerbix] = useState(null);

    const { user, resourceId } = useMeta();
    const notify = useNotification();

    const {
        inputs,
        setInputs,
        inputChange,
        datepickerChange,
        selectValue,
        selectChange,
        genericChange,
    } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "first_name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
            {
                field: "last_name",
                method: "isEmpty",
                validWhen: false,
                message: "This last name is required.",
            },
            {
                field: "phone",
                method: "isEmpty",
                validWhen: false,
                message: "This phone number is required.",
            },
            {
                field: "date_of_birth",
                method: "isEmpty",
                validWhen: false,
                message: "This date of birth is required.",
            },
            {
                field: "role",
                method: "isEmpty",
                validWhen: false,
                message: "This role is required.",
            },
        ],
        inputs,
    );

    const options = {
        account_id: user.accountId,
        store_id: user.storeId,
        id: resourceId,
    };

    const {
        data,
        loading: getCustomerLoading,
        refetch,
    } = useQuery(QUERY_GET_CUSTOMER, {
        variables: options,
        skip: user.loading,
        fetchPolicy: "network-only",
        onCompleted: resp => {
            setInputs(resp?.getAccount);

            getIdPAccount();
        },
    });

    const [getIdPAccount, { loading: loadingIDPAccount }] = useLazyQuery(
        QUERY_GET_IDP_ACCOUNT,
        {
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                email: data?.getAccount?.email,
            },
            fetchPolicy: "network-only",
            onCompleted: resp => {
                const idPAccount = resp.getIDPAccount;

                let input = {
                    ...inputs,
                    berbix_id: idPAccount?.berbix_id || null,
                    berbix_verified: idPAccount?.berbix_verified || false,
                    berbix_error: idPAccount?.berbix_error || null,
                    email_verified: idPAccount?.email_verified || false,
                    enabled: idPAccount?.enabled || false,
                    id: idPAccount?.id || null,
                    role: idPAccount?.role || null,
                };

                setInputs(input);
                setStatusBerbix(idPAccount?.berbix_verified || false);
                setLoading(false);
            },
            onError: () => {
                setLoading(false);
            },
        },
    );

    const getAccountUpdateInput = (newTreezIds = null) => {
        return {
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            email: inputs.email,
            phone: inputs.phone,
            driver_license_id: inputs.driver_license_id,
            latitude: inputs.latitude,
            longitude: inputs.longitude,
            address1: inputs.address1,
            address2: inputs.address2,
            city: inputs.city,
            city_code: inputs.city_code,
            country: inputs.country,
            country_code: inputs.country_code,
            province: inputs.province,
            province_code: inputs.province_code,
            zip: inputs.zip,
            tags: inputs.tags,
            accept_marketing: inputs.accept_marketing,
            photo: inputs.photo,
            driver_license_photo: inputs.driver_license_photo,
            date_of_birth: inputs.date_of_birth,
            treez_ids: newTreezIds !== null ? newTreezIds : inputs.treez_ids,
        };
    };

    const [resetPassword, { loading: loadingReset }] = useMutation(
        MUTATION_FORGOT_USER_PASSWORD,
        {
            refetchQueries: [],
            onCompleted: resp => {
                notify("Reset Password Sent Successfully", "success");
            },
            onError: () => {
                notify(
                    "Error Sending the Reset Password Email to the customer",
                    "error",
                );
            },
        },
    );

    const [mutateIDP, { loading: loadingIDP }] = useMutation(
        MUTATION_UPDATE_IDP_ACCOUNT,
        {
            onCompleted: resp => {
                notify("Customers Updated Successfully", "success");
                setInputs({
                    ...inputs,
                    enabled: !inputs.enabled,
                });
            },
            onError: () => {
                notify("Error Updating Customer", "error");
            },
        },
    );

    const [
        mutateChangeBerbixVerificationStatus,
        { loading: loadingChangeBerbixVerificationstatus },
    ] = useMutation(MUTATION_UPDATE_ACCOUNT_BERBIX_VERIFICATION_STATUS, {
        onCompleted: () => {
            setInputs({ ...inputs, note: "" });
            setStatusBerbix(inputs.berbix_verified);
            notify(
                "Account Verification Status Updated Successfully",
                "success",
            );
        },
        onError: error => {
            notify(error.message, "error");
        },
    });

    const [mutate, { loading: loadingUpdate }] = useMutation(
        MUTATION_UPDATE_ACCOUNT,
        {
            onCompleted: () => {
                refetch();
                notify("Account Updated Successfully", "success");
            },
            onError: () => {
                notify("Error Updating The Customer", "error");
            },
        },
    );

    const sendTheResetPasswordEmail = async () => {
        resetPassword({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                email: inputs.email,
            },
        });
    };

    const updateCustomers = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            mutate({
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                    input: getAccountUpdateInput(null),
                },
            });
        }
    };

    const updateTreezIds = async newTreezIds => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            mutate({
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                    input: getAccountUpdateInput(newTreezIds),
                },
            });
        }
    };

    const updateIDPAccountStatus = () => {
        mutateIDP({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    email: inputs.email,
                    enable: !inputs.enabled,
                },
            },
        });
    };

    const changeBerbixVerificationStatus = () => {
        mutateChangeBerbixVerificationStatus({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    email: inputs.email,
                    accept: inputs.berbix_verified,
                    note: inputs.note || null,
                },
            },
        });
    };

    const _loadingUpdate = loadingUpdate || loadingIDP;

    return (
        <>
            <Content>
                <Portlet
                    className="customers__details"
                    header={{
                        title: "Customer",
                        subtitle: "Customer details",
                    }}
                    sticky
                    toolbar={
                        <>
                            {!loadingIDPAccount && !getCustomerLoading && (
                                <Button
                                    className="btn__toolbar"
                                    color={
                                        inputs.enabled ? "danger" : "warning"
                                    }
                                    disabled={loadingIDP}
                                    onClick={updateIDPAccountStatus}
                                >
                                    <Spinner show={false} sm />
                                    <Icon icon="alert" />
                                    {inputs.enabled ? "Deactivate" : "Activate"}
                                </Button>
                            )}
                            <Button
                                className="ml-3 btn__toolbar"
                                color="primary"
                                disabled={_loadingUpdate}
                                onClick={updateCustomers}
                            >
                                <Spinner show={_loadingUpdate} sm />
                                {!_loadingUpdate && (
                                    <i className="fas fa-save" />
                                )}
                                Update
                            </Button>
                        </>
                    }
                >
                    <Tabs
                        navs={[
                            {
                                title: "General",
                                content: (
                                    <GeneralPane
                                        datepickerChange={datepickerChange}
                                        inputChange={inputChange}
                                        inputs={inputs}
                                        loading={loading}
                                        loadingReset={loadingReset}
                                        loadingUpdate={_loadingUpdate}
                                        resetPassword={
                                            sendTheResetPasswordEmail
                                        }
                                        selectChange={selectChange}
                                        selectValue={selectValue}
                                        updateTreezIds={updateTreezIds}
                                        validator={validator}
                                    />
                                ),
                            },
                            {
                                title: "Verification",
                                content: (
                                    <VerificationPane
                                        changeBerbixVerificationStatus={
                                            changeBerbixVerificationStatus
                                        }
                                        inputChange={inputChange}
                                        inputs={inputs}
                                        loading={loading}
                                        loadingReset={
                                            loadingChangeBerbixVerificationstatus
                                        }
                                        selectChange={selectChange}
                                        selectValue={selectValue}
                                        statusBerbix={statusBerbix}
                                        validator={validator}
                                    />
                                ),
                            },
                            {
                                title: "Notes",
                                content: (
                                    <Notes
                                        customers={inputs}
                                        loading={getCustomerLoading}
                                        setCustomers={setInputs}
                                        user={user}
                                    />
                                ),
                            },
                            {
                                title: "Activity Logs",
                                content: (
                                    <ActivityLogs
                                        inputs={inputs}
                                        loading={getCustomerLoading}
                                    />
                                ),
                            },
                        ]}
                    />
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default CustomerForm;
