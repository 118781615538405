import aws_exports from "./config/aws/aws-exports";
import Authenticator from "aws-amplify-react/lib/Auth/Authenticator";
import SignIn from "aws-amplify-react/lib/Auth/SignIn";
import ConfirmSignIn from "aws-amplify-react/lib/Auth/ConfirmSignIn";
import RequireNewPassword from "aws-amplify-react/lib/Auth/RequireNewPassword";
import VerifyContact from "aws-amplify-react/lib/Auth/VerifyContact";
import ForgotPassword from "aws-amplify-react/lib/Auth/ForgotPassword";
import AuthPiece from "aws-amplify-react/lib/Auth/AuthPiece";
import { ApolloProvider } from "@apollo/react-common";
import data from "./apollo/data";
import { BrowserRouter } from "react-router-dom";
import Layout from "./base/layout/Layout";
import { ToastContainer } from "react-toastify";
import { containerSettings } from "./config/components/toast";
import { StoreProvider } from "context";
import { Hub } from "aws-amplify";
import React, { useEffect } from "react";

class AuthenticatorWrapper extends AuthPiece {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signedIn"];
    }

    showComponent = () => {
        return (
            <ApolloProvider client={data}>
                <BrowserRouter>
                    <StoreProvider>
                        <Layout />
                    </StoreProvider>
                    <ToastContainer {...containerSettings} />
                </BrowserRouter>
            </ApolloProvider>
        );
    };
}

const App = () => {
    useEffect(() => {
        const listener = data => {
            const { event } = data.payload;
            // we are doing this to make sure the store menu does
            // not get cached with the previuos selected sotre
            // when changing the login between the stores
            if (event === "signIn") {
                window.location.reload();
            }
        };

        Hub.listen("auth", listener);

        return () => Hub.remove("auth", listener);
    }, []);

    return (
        <section className="auth_container">
            <Authenticator
                amplifyConfig={aws_exports}
                hide={[
                    SignIn,
                    ConfirmSignIn,
                    RequireNewPassword,
                    VerifyContact,
                    ForgotPassword,
                ]}
                hideDefault={true}
            >
                <SignIn />
                <ConfirmSignIn />
                <RequireNewPassword />
                <VerifyContact />
                <ForgotPassword />
                <AuthenticatorWrapper />
            </Authenticator>
        </section>
    );
};

export default App;
