import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import Spinner from "../../components/spinner/Spinner";

const useConfirm = (callback, message = null, onCancel = null) => {
    const [open, isOpen] = useState([false, []]);
    const [loading, isLoading] = useState(false);

    const launch = (...args) => {
        isOpen([true, args]);
    };

    const confirm = async () => {
        isLoading(true);

        await callback(...open[1]);

        isOpen([false, open[1]]);
    };

    const cancel = () => {
        if (onCancel) {
            onCancel(...open[1]);
        }
        isOpen([false, open[1]]);
    };

    const modal = (
        <Modal
            isOpen={open[0]}
            onClosed={() => {
                isLoading(false);
            }}
            size="sm"
        >
            <ModalBody>
                {message || "Are You Sure You Want To Do This Action?"}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={loading} onClick={confirm}>
                    <Spinner show={loading} sm />
                    Confirm
                </Button>
                <Button
                    className="ml-3"
                    color="secondary"
                    disabled={loading}
                    onClick={cancel}
                    outline
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );

    return [modal, launch];
};

export default useConfirm;
