import gql from "graphql-tag";

let Customer = {};
Customer.fragments = {
    attributes: gql`
        fragment CustomerFragment on Account {
            entity_id
            key
            first_name
            last_name
            email
            has_account
            checkin_from_dispensary
            phone
            active
            leaf_logic_id
            driver_license_id
            latitude
            longitude
            address1
            address2
            city
            city_code
            country
            country_code
            province
            province_code
            zip
            created_at
            updated_at
            total_order_expense
            tags
            accept_marketing
            photo
            driver_license_photo
            loyalty_points
            date_of_birth
            notes
            treez_ids {
                store_id
                id
            }
            activity_logs {
                action
                user
                status
                timestamp
            }
        }
    `,
};

export const QUERY_CUSTOMER_LIST = gql`
    query listAccounts(
        $account_id: String!
        $store_id: String!
        $cursor: String
        $account_filter: AccountFilter
    ) {
        listAccounts(
            account_id: $account_id
            store_id: $store_id
            cursor: $cursor
            account_filter: $account_filter
        ) {
            cursor
            accounts {
                ...CustomerFragment
            }
        }
    }
    ${Customer.fragments.attributes}
`;

export const QUERY_GET_CUSTOMER = gql`
    query getAccount($account_id: String!, $store_id: String!, $id: String!) {
        getAccount(account_id: $account_id, store_id: $store_id, id: $id) {
            ...CustomerFragment
        }
    }
    ${Customer.fragments.attributes}
`;

export const QUERY_GET_CUSTOMER_BY_EMAIL = gql`
    query getAccountByEmail(
        $account_id: String!
        $store_id: String!
        $email: String!
    ) {
        getAccountByEmail(
            account_id: $account_id
            store_id: $store_id
            email: $email
        ) {
            ...CustomerFragment
        }
    }
    ${Customer.fragments.attributes}
`;

export const QUERY_GET_IDP_ACCOUNT = gql`
    query getIDPAccount(
        $account_id: String!
        $store_id: String!
        $email: String!
    ) {
        getIDPAccount(
            account_id: $account_id
            store_id: $store_id
            email: $email
        ) {
            id
            berbix_id
            berbix_verified
            berbix_error
            email_verified
            email
            role
            enabled
        }
    }
`;

export const MUTATION_UPDATE_IDP_ACCOUNT = gql`
    mutation updateIDPAccount(
        $account_id: String!
        $store_id: String!
        $input: UpdateIDPAccount!
    ) {
        updateIDPAccount(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_ADD_NOTES_TO_ACCOUNT = gql`
    mutation addNotesToAccount(
        $account_id: String!
        $store_id: String!
        $input: AddAccountNotesInput!
    ) {
        addNotesToAccount(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...CustomerFragment
        }
    }
    ${Customer.fragments.attributes}
`;
