import {
    Row,
    Col,
    FormGroup,
    Label,
    FormFeedback,
    Button,
    TabPane,
    InputGroup,
    InputGroupAddon,
    Input,
} from "reactstrap";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import classNames from "classnames";
import {
    Configure,
    InstantSearch,
    useInstantSearch,
} from "react-instantsearch-hooks-web";
import { getAlgoliaClient } from "algolia";

import { NOT_AVAILABLE, ROLES } from "@/config";
import {
    CheckBox,
    Section,
    SectionTitle,
    InputGroupText,
    Badge,
    Select,
    Spinner,
    Icon,
} from "@/components";
import { useMeta, useConfirm } from "@/hooks";
import { useStoreContext } from "context";
import { currencyFormat } from "utils/utils";
import _ from "lodash";

import OrderHistory from "../components/OrderHistory";
import { useEffect, useState } from "react";

const algoliaClient = getAlgoliaClient();

const GeneralPane = ({
    tabId,
    datepickerChange,
    inputs,
    inputChange,
    loading,
    loadingReset,
    loadingUpdate,
    resetPassword,
    selectValue,
    selectChange,
    updateTreezIds,
    validator,
}) => {
    const { store } = useStoreContext();
    const verified = inputs?.email_verified || false;
    const [treezIds, setTreezIds] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [tempValue, setTempValue] = useState("");
    const [actionDeleteActive, setActionDeleteActive] = useState(false);
    const dateofbirth = moment(inputs.date_of_birth).utc().format("MM/DD/YYYY");
    const { user } = useMeta();

    const handleCancel = () => {
        setEditIndex(null);
        setTempValue("");
        setActionDeleteActive(false);
    };

    useEffect(() => {
        setTreezIds(inputs?.treez_ids ?? []);
    }, [inputs?.treez_ids]);

    useEffect(() => {
        handleCancel();
    }, [loadingUpdate]);

    const [confirm, launch] = useConfirm(
        () => {
            const updatedTreezIds = _.cloneDeep(treezIds);
            updatedTreezIds[editIndex].id = tempValue;
            setTreezIds(updatedTreezIds);
            handleCancel();
            updateTreezIds(updatedTreezIds);
        },
        "Are you sure you want to change the customer POS's ID?",
        handleCancel,
    );

    const [confirmDelete, launchDelete] = useConfirm(
        () => {
            const newTreezIds = treezIds?.filter(
                (_, index) => index !== editIndex,
            );
            setTreezIds(newTreezIds);
            handleCancel();
            updateTreezIds(newTreezIds);
        },
        "Are you sure you want to disconnect the customer's POS ID?",
        handleCancel,
    );

    const getName = () => {
        let name = null;

        if (inputs?.first_name) {
            name = `${inputs?.first_name}`;
        }

        if (inputs?.last_name) {
            name = `${name} ${inputs?.last_name}`;
        }

        if (name) {
            return name;
        }

        return NOT_AVAILABLE;
    };

    const handleEditCustomerID = (index, currentId) => {
        setEditIndex(index);
        setTempValue(currentId);
    };

    const handleDeleteCustomerID = index => {
        setEditIndex(index);
        setActionDeleteActive(true);
        launchDelete();
    };

    return (
        <TabPane tabId={tabId}>
            <Section>
                {store?.algolia_order_index ? (
                    <InstantSearch
                        indexName={store?.algolia_order_index}
                        searchClient={algoliaClient}
                    >
                        <Configure
                            filters={`email:${inputs.email} AND store_id:"${user.storeId}" AND account_id:"${user.accountId}"`}
                            hitsPerPage={30}
                        />
                        {!inputs.has_account ? (
                            <Row>
                                <Col className="account__status" lg={6}>
                                    {loading ? (
                                        <div className="content-placeholder">
                                            <div className="cp-w-lg cp-h-md" />
                                        </div>
                                    ) : (
                                        <Badge
                                            className={"status__error"}
                                            label="This user does not have login credentials"
                                            md={true}
                                        />
                                    )}
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col className="customers__name" lg={8}>
                                {loading ? (
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-md" />
                                    </div>
                                ) : (
                                    <p>{`${getName()} (Created at ${moment(
                                        inputs.created_at,
                                    ).format("MMMM Do YYYY - HH:mm:ss")})`}</p>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SectionTitle md>Details</SectionTitle>
                            </Col>
                        </Row>
                        {loading ? (
                            <Row>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="content-placeholder">
                                        <div className="cp-w-lg cp-h-lg" />
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>First Name</Label>
                                        <Input
                                            defaultValue={
                                                inputs.first_name ?? ""
                                            }
                                            invalid={
                                                validator.first_name.isInvalid
                                            }
                                            name="first_name"
                                            onChange={inputChange}
                                            placeholder="First Name"
                                        />
                                        <FormFeedback>
                                            {validator.first_name.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>Last Name</Label>
                                        <Input
                                            defaultValue={
                                                inputs.last_name ?? ""
                                            }
                                            invalid={
                                                validator.last_name.isInvalid
                                            }
                                            name="last_name"
                                            onChange={inputChange}
                                            placeholder="Last Name"
                                        />
                                        <FormFeedback>
                                            {validator.last_name.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>Phone Number</Label>
                                        <Input
                                            defaultValue={inputs.phone ?? ""}
                                            invalid={validator.phone.isInvalid}
                                            name="phone"
                                            onChange={inputChange}
                                            placeholder="(888) 888-8888"
                                            type="tel"
                                        />

                                        <FormFeedback>
                                            {validator.phone.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <InputGroup
                                            className={classNames(
                                                "input_email",
                                            )}
                                        >
                                            <Input
                                                defaultValue={
                                                    inputs.email ?? ""
                                                }
                                                disabled
                                                name="email"
                                                onChange={inputChange}
                                                placeholder="Email"
                                                required
                                                type="email"
                                            />
                                            {inputs?.email ? (
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="text_status"
                                                >
                                                    <Badge
                                                        className={classNames({
                                                            ["status__error"]:
                                                                !verified,
                                                            ["status__success"]:
                                                                verified,
                                                        })}
                                                        label={
                                                            verified
                                                                ? "Verified"
                                                                : "Not Verified"
                                                        }
                                                    />
                                                </InputGroupAddon>
                                            ) : null}
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>Date of Birth</Label>
                                        <DateRangePicker
                                            autoApply
                                            maxDate={moment()
                                                .subtract(21, "year")
                                                .format("MM/DD/YYYY")}
                                            onApply={(e, picker) => {
                                                datepickerChange(
                                                    picker,
                                                    "date_of_birth",
                                                );
                                            }}
                                            showDropdowns
                                            singleDatePicker
                                            startDate={
                                                dateofbirth != "Invalid date"
                                                    ? dateofbirth
                                                    : moment()
                                                          .subtract(21, "year")
                                                          .format("MM/DD/YYYY")
                                            }
                                        >
                                            <Input
                                                append={
                                                    <InputGroupText>
                                                        <i className="fas fa-calendar-alt" />
                                                    </InputGroupText>
                                                }
                                                invalid={
                                                    validator.date_of_birth
                                                        .isInvalid
                                                }
                                                placeholder="Date of birth"
                                                readOnly
                                                value={
                                                    inputs.date_of_birth
                                                        ? moment(
                                                              inputs.date_of_birth,
                                                          )
                                                              .utc()
                                                              .format(
                                                                  "MM/DD/YYYY",
                                                              )
                                                        : ""
                                                }
                                            />
                                        </DateRangePicker>
                                        <FormFeedback>
                                            {validator.date_of_birth.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col lg={3}>
                                    <FormGroup>
                                        <Label>Role</Label>
                                        <Select
                                            controlName="role"
                                            invalid={validator.role.isInvalid}
                                            onChange={selectChange}
                                            options={ROLES}
                                            value={selectValue(
                                                inputs.role,
                                                ROLES,
                                            )}
                                        />
                                        <FormFeedback>
                                            {validator.role.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                {inputs.enabled && (
                                    <Col lg={3}>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Button
                                                className="btn__form"
                                                color="primary"
                                                disabled={loadingReset}
                                                onClick={resetPassword}
                                            >
                                                <Spinner
                                                    show={loadingReset}
                                                    sm
                                                />
                                                Reset password
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                        )}
                        <Row className="mt-3">
                            <Col>
                                <SectionTitle md>
                                    Account Information
                                </SectionTitle>
                            </Col>
                        </Row>
                        {loading ? null : (
                            <Col>
                                <Row className="account__info_table">
                                    <div className="col__info">
                                        <Label>ID</Label>
                                        <div>
                                            {inputs.entity_id || NOT_AVAILABLE}
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label>IDP ID</Label>
                                        <div>{inputs.id || NOT_AVAILABLE}</div>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label>Created at</Label>
                                        <div>
                                            {moment(inputs.created_at).format(
                                                "MMMM Do YYYY - HH:mm:ss",
                                            )}
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label> Enrolment origin</Label>
                                        <Row className="row__info">
                                            <Row className="subrow__info">
                                                <div>GapCommerce</div>
                                                <Badge
                                                    className={"status__info"}
                                                    label={"Website"}
                                                    md={true}
                                                />
                                            </Row>
                                            <Row className="subrow__info">
                                                <div>Mode</div>
                                                <Badge
                                                    className={"status__info"}
                                                    label={"Online"}
                                                    md={true}
                                                />
                                            </Row>
                                        </Row>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label>
                                            <span>Accounts | Treez</span>
                                            <span
                                                style={{ marginRight: "52px" }}
                                            >
                                                Customer ID
                                            </span>
                                        </Label>
                                        <ul>
                                            {treezIds?.map((a, index) => (
                                                <li
                                                    className="col__info_item"
                                                    key={index}
                                                >
                                                    <Badge
                                                        className={
                                                            "status__info"
                                                        }
                                                        label={a.store_id}
                                                        md={true}
                                                    />
                                                    <div className="col__info_item col__info_item_input">
                                                        {editIndex === index &&
                                                        !actionDeleteActive ? (
                                                            <>
                                                                <Input
                                                                    bsSize="sm"
                                                                    className={
                                                                        tempValue ===
                                                                        ""
                                                                            ? "is-invalid"
                                                                            : ""
                                                                    }
                                                                    onChange={e =>
                                                                        setTempValue(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        )
                                                                    }
                                                                    type="number"
                                                                    value={
                                                                        tempValue
                                                                    }
                                                                />

                                                                <Button
                                                                    color="link"
                                                                    disabled={
                                                                        tempValue ===
                                                                        ""
                                                                    }
                                                                    onClick={() =>
                                                                        launch()
                                                                    }
                                                                    title="Save Customer Id"
                                                                >
                                                                    <Icon icon="save" />
                                                                </Button>
                                                                <Button
                                                                    color="link"
                                                                    onClick={
                                                                        handleCancel
                                                                    }
                                                                    title="Cancel Edit"
                                                                >
                                                                    <Icon icon="cancel" />
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span>
                                                                    {a.id}
                                                                </span>
                                                                <Button
                                                                    color="link"
                                                                    onClick={() =>
                                                                        handleEditCustomerID(
                                                                            index,
                                                                            a.id,
                                                                        )
                                                                    }
                                                                    title="Change Customer Id"
                                                                >
                                                                    <Icon icon="pencil" />
                                                                </Button>
                                                                <Button
                                                                    color="link"
                                                                    onClick={() =>
                                                                        handleDeleteCustomerID(
                                                                            index,
                                                                        )
                                                                    }
                                                                    title="Delete Record"
                                                                >
                                                                    <Icon icon="delete" />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                            {treezIds?.length === 0 && (
                                                <li
                                                    className="col__info_item"
                                                    key={"placeholder"}
                                                >
                                                    <span className="col__info_item_placeholder">
                                                        No Customer POS ID is
                                                        associated
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label>Status</Label>
                                        <div>
                                            {inputs?.has_account
                                                ? "Active"
                                                : "Deactive"}
                                        </div>
                                    </div>
                                    <div className="separator" />
                                    <div className="col__info">
                                        <Label>Marketing</Label>
                                        <Row className="row__info_marketing">
                                            <CheckBox
                                                checked={
                                                    inputs?.accept_marketing ||
                                                    false
                                                }
                                                disabled
                                            />
                                            Marketing
                                        </Row>
                                    </div>
                                </Row>
                            </Col>
                        )}
                        {!loading ? (
                            <Row>
                                <Stats
                                    totalSpend={inputs.total_order_expense}
                                />
                            </Row>
                        ) : null}

                        <OrderHistory />
                    </InstantSearch>
                ) : null}
            </Section>
            {confirm}
            {confirmDelete}
        </TabPane>
    );
};

export default GeneralPane;

const Stats = ({ totalSpend }) => {
    const {
        results: { nbHits },
    } = useInstantSearch();

    return (
        <>
            <Row className="mt-5 account__info_estadistic_title">
                <Col>
                    <SectionTitle md>Orders Information</SectionTitle>
                </Col>
            </Row>
            <Row className="account__info_estadistic">
                <Col className="card_info">
                    <Label>Lifetime Spend</Label>
                    <span>{currencyFormat(totalSpend)}</span>
                </Col>
                <Col className="card_info">
                    <Label>Average Spend</Label>
                    <span>
                        {nbHits > 0
                            ? currencyFormat(totalSpend / nbHits)
                            : "$0"}{" "}
                    </span>
                </Col>
                <Col className="card_info">
                    <Label>Total Orders</Label>
                    <span>{nbHits}</span>
                </Col>
            </Row>
        </>
    );
};
